@forward './styles/material';
@forward './styles/global';

@use 'material-icons/iconfont/material-icons.css';

@use '../node_modules/@adnova/jf-ng-components/assets/styles/base/typo';


@layer bootstrap, material;

@layer bootstrap {
  @import "bootstrap/scss/bootstrap";
}
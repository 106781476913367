@use '@adnova/jf-ng-components/config' as *;
@use '@adnova/jf-ng-components/lib';

@font-face {
  font-family: OpenSans;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url(../assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-BoldItalic.ttf) format('truetype');
}

@layer material {
  * {
    font-family: OpenSans;
  }
}

@include lib.app-preset-skeleton;

h3 {
  font-family: OpenSans !important;
  margin: 0px 0px 6px !important;
}

.shadow {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

mat-form-field {
  button {
    &.mat-icon-button:enabled[mat-icon-button] {
      color: currentColor !important;
    }
  }
}

button {

  &.mat-raised-button {
    box-shadow: none !important;
  }

  &.mat-raised-button:enabled {

    &:hover,
    &:focus {
      background-color: var(--accent-hover) !important;

      &[color="primary"] {
        background-color: var(--primary-hover) !important;
      }
    }
  }

  &.mat-raised-button.ordinary {
    background: #e5e5e5;
    color: white;
  }

  &.mat-stroked-button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    box-sizing: content-box;

    ku-icon {
      width: 16px;
      height: 16px;
    }
  }

  &.mat-stroked-button.mat-primary {
    border-color: var(--primary);
  }

  &.mat-stroked-button.mat-accent {
    border-color: var(--accent);
  }

  &.mat-stroked-button.mat-warn {
    border-color: var(--warn);
  }

  &.mat-stroked-button:enabled,
  &.mat-icon-button:enabled {
    &[mat-icon-button] {
      color: var(--accent) !important;
    }

    &:hover,
    &:focus {
      border-color: var(--accent-hover) !important;
      color: var(--accent-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }

  &.mat-flat-button:enabled {

    &:hover,
    &:focus {
      background-color: var(--tertiary-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}

snack-bar-container.error {
  background: red;
  color: white;
  font-size: larger;
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-expansion-panel-content {
  font: 400 13px / 20px OpenSans, "OpenSans", "Open Sans", "Helvetica Neue", sans-serif !important;
}


/**
 * FORM STYLING
 * ==============
 * The following lines overwrite the Material Form Element styling
 */

.mat-form-field {
  width: 460px;
  height: 50px;
  font-size: 13px;
  color: #414141;

  .mat-input-element {
    caret-color: currentColor !important;
  }

  &-infix {
    border-top: 0.74375em solid transparent !important;
    height: 49px;
  }

  &-suffix {
    top: 0.27em !important;
  }

  &-outline-thick {
    color: var(--bs-gray-600) !important; // INFO: Only changes the color, not the behavior. Do not Change in BorderColor
  }

  label {
    overflow: visible !important;
  }

  button {
    &.mat-icon-button:enabled[mat-icon-button] {
      color: currentColor !important;
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      color: var(--bs-gray-700) !important;
    }
  }

  &.ng-invalid {
    .mat-form-field-outline-thick > div {
      color: var(--bs-danger) !important;
    }

    .mat-input-element,
    .mat-form-field-label {
      caret-color: var(--bs-danger) !important;
      color: var(--bs-danger) !important;
    }
  }
}

input.mat-input-element:disabled {
  color: #9E9E9E;
}

label .ng-star-inserted {
  display: inline-block;
}

.mat-select-min-line {
  display: inline-block;
  transform: translateY(-2.5px);
}

input.mat-input-element {
  color: #000;
}

input.mat-input-element:disabled {
  color: #9E9E9E;
}
